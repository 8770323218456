import React from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import Ftlogo from '../../assets/images/ft-logo.png';
import { useAppContext } from '../../AppContext';
import { TruncateString } from '../../utils/helper';

function Sidebar({ showWallet, setShowWallet }) {
  const { Disconnect } = useAppContext();
  const handleWalletClose = () => {
    Disconnect();
    setShowWallet(false);
  };
  const { userData, projectData } = useAppContext();

  return (
    <Offcanvas show={showWallet} onHide={setShowWallet} placement="end">
      <Offcanvas.Header closeButton>
        <h5>Wallet</h5>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <p className="wallet-txt">
          Address:{' '}
          <span>{userData ? TruncateString(userData.addr, 5) : '......................'}</span>
        </p>
        <p className="wallet-txt">
        Tokens Owned: {projectData ? (projectData.assetT === 'cn' ? projectData.project : '$' + projectData.asset.split(':')[0].toUpperCase()) : 0}{' '}
          <span>{userData ? userData.amt : 0}</span>
        </p>
        <div className="wallets-btn">
          <Button className="th-btn" onClick={() => handleWalletClose()}>
            <span className="icon icon-wallet"></span>
            <span className="txt">Disconnect Wallet</span>
          </Button>
        </div>

        <div className="d-flex justify-content-center mt-3 position-absolute bottom-0 w-100">
          <img src={Ftlogo} alt="" />
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default Sidebar;
