import React from 'react';
import { useSpring, animated } from 'react-spring';

const NumberCounter = ({ value }) => {
  let valueConv = value;
  if (typeof value === 'string') {
    valueConv = parseFloat(value);
  }
  const { number } = useSpring({
    from: { number: 0 },
    to: { number: valueConv },
    delay: 200,
    config: { duration: 2000 },
  });

  return <animated.span>{number.interpolate((val) => val.toFixed(2))}</animated.span>;
};

export default NumberCounter;
