import React from 'react';
import Slider from 'react-slick/lib/slider';
import { SlickSliderSettings } from '../../utils/helper/SlickSlider';
import { useAppContext } from '../../AppContext';

function MyItems() {
  const { userData, setSelectedDetailsItem, setShowDetailsPopup } = useAppContext();
  const hasItems = userData && userData.items.length > 0;

  const handleClick = (item) => {
    setSelectedDetailsItem(item);
    setShowDetailsPopup(true);
  };

  return (
    <div className="panel">
      <div className="panel-title text-center mb-4">
        <h3>My Items</h3>
      </div>

      {hasItems ? (
        <Slider {...SlickSliderSettings}>
          {userData.items.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / 6);

            if (!resultArray[chunkIndex]) {
              resultArray[chunkIndex] = []; 
            }

            resultArray[chunkIndex].push(item);
            return resultArray;
          }, []).map((group, index) => (
            <div key={index} className="cards-block">
              <div className="row g-3">
                {group.map((item) => (
                  <div key={item._id} className="col-6">
                    <div className="card" onClick={() => handleClick(item)} style={{cursor: 'pointer'}}>
                      <div className="img-block">
                        <img src={item.prev} alt="" />
                      </div>
                      <div className="dis">
                        <h4>{item.rarity === 1 ? (<span>{item.name}</span>) : item.name}</h4>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </Slider>
      ) : (
        <div className="empty-container"></div>
      )}
    </div>
  );
}

export default MyItems;
