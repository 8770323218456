import React, { useEffect, useState } from 'react';

import { useAppContext } from '../../../AppContext';
import { SignXverse, checkForPreviousLogin, requestUnisat } from '../../../utils/helper';

import Ftlogo from '../../../assets/images/ft-logo.png';
import Unisat from '../../../assets/images/unisat.svg';

// import { getCapabilities } from 'sats-connect';
// import { NETWORK_TYPE } from '../../../constant';
// import Verse from '../../../assets/images/verse.svg';

function ConnectPopup() {
  // const [capabilities, setCapabilityState] = useState(false);
  const [error, setError] = useState(null);
  const [selectedProject, setSelectedProject] = useState('');

  const {
    Disconnect,
    setUserData,
    projectData,
    setErrorToast,
    setUserVerified,
    setNotificationToast,
    setItemList
  } = useAppContext();

  const projects = [
    { name: 'Lane', url: 'https://levels.ppline.app/6571bca3381c67cc9bdf87a8' },
    { name: 'Pipeblock', url: 'https://levels.ppline.app/65ce4bf70dbc299a27fdc49c' }
  ];

  const handleProjectChange = (e) => {
    const projectUrl = e.target.value;
    setSelectedProject(projectUrl);
    if (projectUrl) {
      window.location.href = projectUrl;
    }
  };

  useEffect(() => {
    const PID = window.location.pathname.replace('/', '');
    if (!PID) {
      setError(true);
    } else {
      localStorage.setItem('pipeline_PID', PID);
    }
  }, []);
  useEffect(() => {
      checkForPreviousLogin(setUserVerified, setUserData, setItemList, Disconnect);
  }, []);

  return (
    <div className="modal-content connect-pp">
      <div className="modal-body p-3">
        <button
          type="button"
          className="btn-close d-none"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
        <div className="popup-content">
          <div className="text-center">
            {window.location.pathname === '/' ? (
              <img src={Ftlogo} alt="" />
            ) : projectData && projectData.imgUrl ? (
              <img className="main-project-title" src={projectData.imgUrl} alt="Project_Logo" />
            ) : (
              <img src={Ftlogo} alt="" />
            )}
          </div>
          {error ? (
            <div className="w-75 mx-auto d-block mt-4">
              {/* <h4 className="w-100 text-center fw-bold">ERROR: NO PROJECT ID FOUND.</h4> */}
              <div className="mt-3">
                <select
                  id="projectSelect"
                  className="form-select"
                  value={selectedProject}
                  onChange={handleProjectChange}
                >
                  <option value="">Choose a project</option>
                  {projects.map(project => (
                    <option key={project.name} value={project.url}>
                      {project.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          ): (
            <div className="w-75 mx-auto d-block mt-4">
              {typeof window.unisat !== 'undefined' ? (
                <button
                  disabled={projectData && !projectData.active}
                  onClick={() =>
                    requestUnisat(setUserData,setItemList, setErrorToast, setUserVerified, setNotificationToast)
                  }
                  className="align-items-center d-flex justify-content-center mt-3 th-btn mb-5"
                >
                  <div>
                    <span className="txt text-capitalize me-2 lh-lg">Connect to</span>
                    <img src={Unisat} alt="" height="20" />
                  </div>
                </button>
              ) : (
                <>
                  <button className="align-items-center d-flex justify-content-center mt-3 th-btn mb-5">
                    <a href="https://unisat.io/download" target="_blank">
                      <span className="txt text-capitalize me-2 lh-lg">Install</span>
                      <img src={Unisat} alt="" height="20" />
                    </a>
                  </button>
                </>
              )}
              {/* <button className="align-items-center d-flex justify-content-center mt-3 th-btn ">
                {capabilities === 'loaded' ? (
                  <div
                    onClick={() =>
                      SignXverse(setErrorToast, setNotificationToast, setUserVerified, setUserData)
                    }
                  >
                    <span className="txt text-capitalize me-2 lh-lg">Connect to</span>
                    <img src={Verse} alt="" height="20" />
                  </div>
                ) : (
                  <a href="https://www.xverse.app/download" target="_blank">
                    <span className="txt text-capitalize me-2 lh-lg">Install</span>
                    <img src={Verse} alt="" height="20" />
                  </a>
                )}
              </button> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ConnectPopup;
