import React, { useEffect } from 'react';

import Panel from '../Panel';
import XRPOverview from '../XRPOverview';
import MyItems from '../MyItems';
import ClaimRewards from '../ClaimRewards';
import { useAppContext } from '../../AppContext';
import { refreshUserData } from '../../utils/helper';
import { getProjectData} from '../../utils/api';

function Dashboard() {
  const { showXRPOverview, setUserData, setProjectData, setErrorToast, Disconnect, userData} =
    useAppContext();

  useEffect(() => {
    init();
    (async function () {
      const PID = window.location.pathname.replace('/', '') || localStorage.getItem('pipeline_PID');
      if (PID) {
        try {
          let projectData = await getProjectData(PID, setErrorToast);
          document.title = `${projectData.project} | Levels`;
          setProjectData(projectData);
        } catch (error) {
          Disconnect();
          window.location.href = '/';
        }
      }
    })();
  }, []);

  const init = async () => {
    const {
      bitcoin: { websocket },
    } = window.mempoolJS();

    const ws = websocket.initClient({
      options: ['blocks'],
    });

    ws.addEventListener('message', function incoming({ data }) {
      const res = JSON.parse(data.toString());
      if (res.block) {
        refreshUserData(setUserData);
      }
    });
  };

  return (
    <section className="dashboard">
      {userData && (userData.status === 'maturing' || userData.status === 'inactive' || userData.status === 'deactivated') ? (
        <div className="container">
          <XRPOverview />
        </div>
      ) : (
        <div className="container">
          <div className="row gy-3">
            <div className="col-lg-4">
              <Panel />
            </div>
            <div className="col-lg-4">
              <MyItems />
            </div>
            <div className="col-lg-4">
              <ClaimRewards />
            </div>
          </div>
        </div>
      )}
    </section>
  );
  
}

export default Dashboard;