import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Dashboard from './components/Dashboard';
import { Modal } from 'react-bootstrap';
import StackinPopup from './components/Popups/StakingPopup';
import ToastComponent from './components/Toast';
import ConnectPopup from './components/Popups/ConnectPopup';
import ClaimPopup from './components/Popups/ClaimPopup';
import DetailsPopup from './components/Popups/DetailsPopup';

import { useAppContext } from './AppContext';

function App() {
  const {
    showWallet,
    userVerified,
    setShowToast,
    setShowWallet,
    showClaimPopup,
    setShowClaimPopup,
    setShowConnectPopup,
    setShowStakingPopup,
    showDetailsPopup,
    setShowDetailsPopup
  } = useAppContext();

 
  return (
    <>
      <main>
        <Header setShowWallet={setShowWallet} />
        <Sidebar showWallet={showWallet} setShowWallet={setShowWallet} />
        <Dashboard />
        <ToastComponent />
      </main>
      {/* Claim Popup */}
      <Modal show={showClaimPopup} size="lg" centered onHide={() => setShowClaimPopup(false)}>
        <Modal.Body>
          <ClaimPopup />
        </Modal.Body>
      </Modal>

      <Modal show={showDetailsPopup} size="lg" centered onHide={() => setShowDetailsPopup(false)}>
        <Modal.Body>
          <DetailsPopup/>
        </Modal.Body>
      </Modal>

      {/* Connect Popup */}
      
      <Modal show={!userVerified.verified} size="lg" centered>
        <Modal.Body>
          <ConnectPopup />
        </Modal.Body>
      </Modal>

      {/* Staking Popup */}
      <Modal show={userVerified.fees > 0} size="lg" centered>
        <Modal.Body>
          <StackinPopup />
        </Modal.Body>
      </Modal>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/tiny-slider/2.9.2/min/tiny-slider.js"></script>
    </>
  );
}

export default App;
