import React from 'react';
import { useAppContext } from '../../AppContext';
import NumberCounter from '../Animations/NumberAnim';
import { formatNumberWithPrecision } from '../../utils/helper';
import {Tooltip} from 'react-tooltip';

function Panel() {
  const { projectData, userData } = useAppContext();

  return (
    <div className="panel text-center">
      <div className="xp-overview">
        {/* <button onClick={() => setShowXRPOverview(true)} className="bg-transparent border-0">
          <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
            <path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" />
          </svg>
        </button> */}
      </div>
      <div className="main-counter"  data-tooltip-id="tooltip" data-tooltip-content={`${userData?.xp ?? 0} ${projectData?.rname ?? 'XP'}`} data-tooltip-place="top">
        <h3>
          <NumberCounter
            value={userData && userData.xp ? formatNumberWithPrecision(userData.xp).number : 0}
          />
        </h3>
        {userData && userData.xp ? formatNumberWithPrecision(userData.xp).abb : ''}
        <p>{`${projectData?.rname ?? 'Experience Points'}`}</p>
      </div>
      <div className="counters">
        <div className="row">
          <div className="col-4">
            <div className="counter dt-tooltip" data-tooltip-id="tooltip"
                      data-tooltip-content={`${userData?.xpBlock ?? 0} ${projectData?.rname ?? 'XP'}`}
            >
              <h4>
                <NumberCounter
                  value={
                    userData && userData.xpBlock
                      ? formatNumberWithPrecision(userData.xpBlock).number
                      : 0
                  }
                />
                {userData && userData.xpBlock
                  ? formatNumberWithPrecision(userData.xpBlock).abb
                  : ''}
              </h4>
              <p>{`${projectData?.rname ?? 'XP'}/block`}</p>
            </div>
          </div>
          <div className="col-4">
            <div className="counter dt-tooltip" data-tooltip-id="tooltip"
                      data-tooltip-content={`${userData?.xpDay ?? 0} ${projectData?.rname ?? 'XP'}`}
                    >
              <h4>
                <NumberCounter
                  value={
                    userData && userData.xpDay
                      ? formatNumberWithPrecision(userData.xpDay).number
                      : 0
                  }
                />
                {userData && userData.xpDay ? formatNumberWithPrecision(userData.xpDay).abb : ''}
              </h4>
              <p>{`${projectData?.rname ?? 'XP'}/day`}</p>
            </div>
          </div>
          <div className="col-4">
            <div className="counter dt-tooltip" data-tooltip="$LANE * XP Rate = 1000 * 2">
              <h4>
                <span className="animated-counter" data-count="300">
                  0
                </span>
              </h4>
              <p>{`${projectData?.rname ?? 'XP'} Spent`}</p>
            </div>
          </div>
        </div>
        {userData && userData.status === 'active' && (
        <div className="maturation">
          <h4>Activated</h4>
        </div>
      )}
      <Tooltip id="tooltip" place="top" type="dark" effect="float" />
      </div>
    </div>
  );
}

export default Panel;
