import React from 'react';

import { useAppContext } from '../../../AppContext';
import { unlockItemWithUnisat } from '../../../utils/helper/index';

function ClaimPopup() {
  const { selectedItem, setShowClaimPopup,  setUserData,
    setErrorToast,
    setNotificationToast,
    setItemList, projectData } = useAppContext();

  return (
    <div className="modal-content">
      <div className="modal-body p-0">
        <button
          type="button"
          onClick={() => setShowClaimPopup(false)}
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
        <div className="row g-0">
          <div className="col-lg-6">
          <video autoPlay loop muted style={{ width: '100%', height: 'auto' }} preload="auto">
          <source src={selectedItem?.asset} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
            {/* <img src={selectedItem?.img} alt="" className="img-fluid" /> */}
          </div>
          <div className="col-lg-6">
            <div className="popup-content">
              <div>
                <h4>Name</h4>
                <h3>{selectedItem?.rarity === 1 ? (<span>{selectedItem?.name}</span>) : selectedItem?.name}</h3>
              </div>
              <div>
                <h4>{`${projectData?.rname ?? 'XP'} Required`}</h4>
                <h3>
                  <span>{selectedItem?.price}</span> {projectData?.rname ?? 'XP'} 
                </h3>
              </div>
              <div>
                <h4>Description</h4>
                <p>{selectedItem?.desc}</p>
              </div>
              <div>
                <a href="#" className="th-btn light" 
                  onClick={() =>
                    unlockItemWithUnisat(setUserData, setItemList, selectedItem?._id, setErrorToast, setNotificationToast, setShowClaimPopup)
                  }>
                  <span className="icon icon-claim"></span>
                  <span className="txt">Claim</span>
                
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClaimPopup;
