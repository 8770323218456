import React from 'react';
import { Toast } from 'react-bootstrap';
import { useAppContext } from '../../AppContext';

import InfoCircle from '../../assets/images/info-circle.svg';
import DollarCircle from '../../assets/images/dollar-circle.svg';

function ToastComponent() {
  const { showToast, setShowToast, isErrorMessage, isNotiMessage } = useAppContext();

  return (
    <div className="toast-container position-fixed bottom-0 end-0 p-3">
      <Toast show={showToast} onClose={() => setShowToast(false)}>
        <Toast.Header closeButton>
          <img
            src={isErrorMessage ? InfoCircle : DollarCircle}
            className="rounded me-2"
            alt="..."
          />
          <strong className="me-auto">{isErrorMessage ? 'Error Alert' : 'Notification'}</strong>
        </Toast.Header>
        <Toast.Body>
          <p>
            {isErrorMessage
              ? isErrorMessage || ''
              : isNotiMessage || ''}
          </p>
        </Toast.Body>
      </Toast>
    </div>
  );
}

export default ToastComponent;
