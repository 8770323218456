import React from 'react';

import Ftlogo from '../../assets/images/ft-logo.png';
import { useAppContext } from '../../AppContext';

function Header({ setShowWallet }) {
  const { userData, projectData, setShowXRPOverview } = useAppContext();

  return (
    <header>
      <div className="container">
        <div className="topbar-btns d-flex justify-content-lg-end justify-content-center my-3">
          <a href="https://whitepaper.ppline.app/Products/Levels" target="_blank" className="th-btn me-3" role="button">
            <span className="icon icon-docs"></span>
            <span className="txt">Docs</span>
          </a>

          <button className="th-btn" onClick={() => setShowWallet(true)}>
            <span className="icon icon-wallet"></span>
            <span className="txt">Wallet</span>
          </button>
        </div>
        <div className="main p-4">
          <div className="logo-block d-flex justify-content-center mb-2 pointer-event">
            {projectData && projectData.imgUrl ? (
              <img
                className="main-project-title"
                src={projectData.imgUrl}
                alt="Project_Logo"
                style={{ cursor: 'pointer' }}
                onClick={() => setShowXRPOverview(false)}
              />
            ) : (
              <img
                src={Ftlogo}
                alt=""
                style={{ cursor: 'pointer' }}
                onClick={() => setShowXRPOverview(false)}
              />
            )}
          </div>
          <div className="level-block d-flex justify-content-center align-items-center">
            {/* <div className="badge">
              <img src={Badge} alt="" />
            </div> */}
            <div className="name">
              LVL{' '}
              <span>{userData ? userData.lvl : '...'}</span>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
