import Prev from '../../assets/images/prev.svg';
import Next from '../../assets/images/next.svg';

export const SlickSliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  customPaging: function (i) {
    return <p className="mt-2">{i + 1}</p>;
  },
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return <img className={className} style={{ ...style }} onClick={onClick} src={Next} alt="Next" />;
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return <img src={Prev} alt="Next" className={className} style={{ ...style }} onClick={onClick} />;
}
