import React, { useEffect, useState } from 'react';
import { useAppContext } from '../../AppContext';
import NumberCounter from '../Animations/NumberAnim';
import { formatNumberWithPrecision } from '../../utils/helper';
import {Tooltip} from 'react-tooltip';
import Countdown from '../Countdown';

function XRPOverview() {
  const { userData, projectData} = useAppContext();

  const [daysUntilMaturity, setDaysUntilMaturity] = useState(0);

  useEffect(() => {
    const fetchCurrentBlockHeight = async () => {
      try {
        const response = await fetch('https://mempool.space/api/blocks/tip/height');
        const currentBlockHeight = await parseInt(await response.text(), 10);
  
        if (userData && userData.since && userData.totalMatD) {
          const totalBlocksForMaturation = userData.totalMatD * 144; 
          const targetBlockHeight = userData.since + totalBlocksForMaturation;
          const blocksRemaining = targetBlockHeight - currentBlockHeight;
  
          const daysUntilMaturity = blocksRemaining / 144;
  
          setDaysUntilMaturity(daysUntilMaturity);
        }
      } catch (error) {
        setDaysUntilMaturity(userData?.matDays ?? 0);
        console.error("Could not fetch current block height:", error);
      }
    };
  
    fetchCurrentBlockHeight();
  }, [userData]);

  return (
    <div className="row gy-3 justify-content-center">
      <div className="col-lg-8">
        <div className="panel no-min">
          <div className="row  align-items-center">
            <div className="col-lg-6">
              <div className="main-counter" data-tooltip-id="tooltip"
                      data-tooltip-content={`${userData?.xp ?? 0} ${projectData?.rname ?? 'XP'}`} data-tooltip-place="top">
                <h3>
                  <NumberCounter
                    value={
                      userData && userData.xp ? formatNumberWithPrecision(userData.xp).number : 0
                    }
                  />
                </h3>
                {userData && userData.xp ? formatNumberWithPrecision(userData.xp).abb : ''}
                <p>{`${projectData?.rname ?? 'Experience Points'}`}</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="counters">
                <div className="row">
                  <div className="col-4">
                    <div
                      className="counter dt-tooltip mx-lg-0"
                      data-tooltip-id="tooltip"
                      data-tooltip-content={`${userData?.xpBlock ?? 0} ${projectData?.rname ?? 'XP'}`}
                    >
                      <h4>
                        <NumberCounter
                          value={
                            userData && userData.xpBlock
                              ? formatNumberWithPrecision(userData.xpBlock).number
                              : 0
                          }
                        />
                        {userData && userData.xpBlock
                          ? formatNumberWithPrecision(userData.xpBlock).abb
                          : ''}
                      </h4>
                      <p>{`${projectData?.rname ?? 'XP'}/block`}</p>
                    </div>
                  </div>
                  <div className="col-4">
                    <div
                      className="counter dt-tooltip mx-lg-0"
                      data-tooltip-id="tooltip"
                      data-tooltip-content={`${userData?.xpDay ?? 0} ${projectData?.rname ?? 'XP'}`}
                    >
                      <h4>
                        <NumberCounter
                          value={
                            userData && userData.xpDay
                              ? formatNumberWithPrecision(userData.xpDay).number
                              : 0
                          }
                        />
                        {userData && userData.xpDay
                          ? formatNumberWithPrecision(userData.xpDay).abb
                          : ''}
                      </h4>
                      <p>{`${projectData?.rname ?? 'XP'}/day`}</p>
                    </div>
                  </div>
                  <div className="col-4">
                    <div
                      className="counter dt-tooltip mx-lg-0"
                      data-tooltip-id="tooltip"
                      // data-tooltip-content={`${userData.xpBlock} XP`}
                    >
                      <h4>
                        <span className="animated-counter" data-count="300">
                          0
                        </span>
                      </h4>
                      <p>{`${projectData?.rname ?? 'XP'} Spent`}</p>
                    </div>
                  </div>
                </div>
              </div>
                          
              {userData && userData.status === 'maturing' && (
                <div className="maturation" >
                  <h4>Maturation</h4>
                  <Countdown days={daysUntilMaturity} />
                  <p>time left for maturity to end</p>
                </div>
              )}

              {userData && userData.status === 'active' && (
                <div className="maturation">
                  <h4>Activated</h4>
                </div>
              )}

              {userData && userData.status === 'inactive' && (
                <div className="maturation">
                  <h4>Inactive</h4>
                </div>
              )}

              {userData && userData.status === 'deactivated' && (
                <div className="maturation">
                  <h4>Deactivated</h4>
                </div>
              )}
                  <Tooltip id="tooltip" place="top" type="dark" effect="float" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default XRPOverview;
