import CryptoJS from 'crypto-js';
import { getAddress, signMessage, sendBtcTransaction } from 'sats-connect';

import { auth, generateNonce, generateItemNonce, getUserData, getItemList, unlockItem } from '../api';
import { NETWORK_TYPE, SECRET_KEY_CRYPTO, ToAddress } from '../../constant';

export async function requestUnisat(
  setUserData,
  setItemList,
  setErrorToast,
  setUserVerified,
  setNotificationToast
) {
  let PROJECT_ID = localStorage.getItem('pipeline_PID');
  if (!PROJECT_ID && window.location.pathname.replace('/', '')) {
    let PID = window.location.pathname.replace('/', '');
    localStorage.setItem('pipeline_PID', PID);
    PROJECT_ID = PID;
  }
  if (window && window.unisat) {
    try {
      const address = (await window.unisat.requestAccounts())[0];

      if (!address.startsWith("bc1p")) {
        setErrorToast("Invalid Address, should be a Taproot Address");
        return false;
      }

      let publicKey = await window.unisat.getPublicKey();
      let nonce = await generateNonce(PROJECT_ID, publicKey);

      if (!nonce) {
        throw new Error('Failed to generate nonce');
      }

      const mesage = `Connect to Levels Platform, Project:${PROJECT_ID} nonce:${nonce}`;
      let signedString = await window.unisat.signMessage(mesage);
      let response = await auth(PROJECT_ID, publicKey, nonce, signedString);

      if (response.verified && response.fees === 0) {
        setNotificationToast('Verified');
        let userData = await getUserData(PROJECT_ID, address);
        setUserData(userData);
        setUserVerified({ ...response, wallet: 'unisat' });
        loginStateSave(address, publicKey, nonce, { ...response, wallet: 'unisat' });


        if(userData && userData.status === 'active'){

            let itemsList = await getItemList(PROJECT_ID, userData.lvl);
            setItemList(itemsList);
        }
      } else if (response.verified && response.fees > 0) {
        setUserVerified({ ...response, wallet: 'unisat' });
        setNotificationToast('Verified');
        loginStateSave(address, publicKey, nonce, { ...response, wallet: 'unisat' });
      } else {
        setErrorToast('Verification Failed');
      }
    } catch (error) {
      setErrorToast(error.message);
    }
  } else {
    setErrorToast('Error connecting to unisat');
  }
}

export async function unlockItemWithUnisat(
  setUserData,
  setItemList,
  item,
  setErrorToast,
  setNotificationToast,
  setShowClaimPopup
) {
  let projectID = localStorage.getItem('pipeline_PID');
  if (!projectID && window.location.pathname.replace('/', '')) {
    let PID = window.location.pathname.replace('/', '');
    localStorage.setItem('pipeline_PID', PID);
    projectID = PID;
  }
  if (window && window.unisat) {
    try {
      const address = (await window.unisat.requestAccounts())[0];

      if (!address.startsWith("bc1p")) {
        setErrorToast("Invalid Address, should be a Taproot Address");
        return false;
      }

      let publicKey = await window.unisat.getPublicKey();

      let nonce = await generateItemNonce(projectID, publicKey, item);
      if (!nonce) {
        throw new Error('Failed to generate nonce');
      }

      const message = `Levels Platform - Spend To Unlock \n\nItem: ${item} \n\nProject: ${projectID.trim()} \n\nnonce: ${nonce.trim()}`;

      let signedString = await window.unisat.signMessage(message);
      let response = await unlockItem(projectID, publicKey, nonce, signedString, item);

      if (response.itemHash) {
        setNotificationToast('Item unlocked');

        let userData = await getUserData(projectID, address);

        setUserData(userData);

        if(userData && userData.status === 'active'){
            let itemsList = await getItemList(projectID, userData.lvl);
            setItemList(itemsList);
        }
        setShowClaimPopup(false);
      } else {
        setErrorToast('Unlock Failed');
      }
    } catch (error) {
      setErrorToast(error.message);
    }
  } else {
    setErrorToast('Error connecting to unisat');
  }
}

export async function SignXverse(
  setErrorToast,
  setNotificationToast,
  setUserVerified,
  setUserData
) {
  const PROJECT_ID = localStorage.getItem('pipeline_PID');
  const getAddressOptions = {
    payload: {
      purposes: ['payment', 'ordinals'],
      message: 'Address for receiving payments',
      network: {
        type: NETWORK_TYPE,
      },
    },
    onFinish: async (response) => {
      try {
        let ordinals = response.addresses.find((item) => item.purpose === 'ordinals');
        let address = ordinals.address;
        let publicKey = ordinals.publicKey;
        let nonce = await generateNonce(PROJECT_ID, publicKey);

        if (!nonce) {
          throw new Error('Failed to generate nonce');
        }

        sessionStorage.setItem('nonce', nonce);
        const mesage = `Connect to Levels Platform, Project:${PROJECT_ID} nonce:${nonce}`;
        await signMessage({
          payload: {
            address: address,
            message: mesage,
          },
          network: {
            type: NETWORK_TYPE,
          },
          onFinish: async (response) => {
            let authResponse = await auth(PROJECT_ID, publicKey, nonce, response);

            if (authResponse.verified && authResponse.fees === 0) {
              setNotificationToast('Verified');
              let userData = await getUserData(PROJECT_ID, address[0]);

              setUserData(userData);
              setUserVerified(authResponse);
              loginStateSave(address, publicKey, nonce, authResponse);
            } else if (authResponse.verified && authResponse.fees > 0) {
              setUserVerified({ ...authResponse, wallet: 'xverse' });
              loginStateSave(address, publicKey, nonce, { ...authResponse, wallet: 'xverse' });
              setNotificationToast('Verified');
            } else {
              setErrorToast('Verification Failed');
            }
          },
          onCancel: () => setErrorToast('Request canceled by User'),
        });
      } catch (error) {
        setErrorToast(error.message);
      }
    },
    onCancel: () => setErrorToast('Request canceled by User'),
  };
  await getAddress(getAddressOptions);
}

export async function payFees(userVerified, setErrorToast, setUserData, setUserVerified) {
  let { fees, wallet } = userVerified;
  const PROJECT_ID = localStorage.getItem('pipeline_PID');
  if (wallet === 'unisat') {
    try {
      await window.unisat.sendBitcoin(ToAddress, fees * 100000000);
      let address = await window.unisat.requestAccounts();
      let userData = await getUserData(PROJECT_ID, address[0]);

      setUserVerified({ ...userVerified, fees: 0 });
      setUserData(userData);
    } catch (error) {
      setErrorToast(error.message);
    }
  } else {
    try {
      const getAddressOptions = {
        payload: {
          purposes: ['payment', 'ordinals'],
          message: 'Address for receiving payments',
          network: {
            type: NETWORK_TYPE,
          },
        },
        onFinish: async (response) => {
          try {
            let ordinals = response.addresses.find((item) => item.purpose === 'payment');

            const sendBtcOptions = {
              payload: {
                network: {
                  type: NETWORK_TYPE,
                },
                recipients: [
                  {
                    address: ToAddress,
                    amountSats: Satoshis(fees),
                  },
                  // you can add more recipients here
                ],
                senderAddress: ordinals.address,
              },
              onFinish: async (response) => {
                let userData = await getUserData(PROJECT_ID, ordinals.address);
                setUserVerified({ ...userVerified, fees: 0 });
                setUserData(userData);
              },
              onCancel: () => {
                throw new Error('Cancelled by user');
              },
            };

            await sendBtcTransaction(sendBtcOptions);
          } catch (error) {
            setErrorToast(error.message);
          }
        },
        onCancel: () => setErrorToast('Request canceled by User'),
      };
      await getAddress(getAddressOptions);
    } catch (error) {
      setErrorToast(error.message);
    }
  }
}

export const getPrecision = (number) => {
  const match = number.toString().match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
  if (!match) return 0;
  return Math.max(0, (match[1] ? match[1].length : 0) - (match[2] ? +match[2] : 0));
};

export async function refreshUserData(setUserData) {
  const PROJECT_ID = localStorage.getItem('pipeline_PID');
  let previousLoginDet = localStorage.getItem('pipeline');
  if (!previousLoginDet) return false;
  let previousLoginDetParsed = decryptData(localStorage.getItem('pipeline'));
  let userData = await getUserData(PROJECT_ID, previousLoginDetParsed.address);

  setUserData(userData);
}

/* global BigInt */
function Satoshis(number) {
  return BigInt(number * 100000000);
}

function loginStateSave(address, publicKey, nonce, userVerified) {
  localStorage.setItem(
    'pipeline',
    encryptData({
      time: new Date(),
      address,
      publicKey,
      nonce,
      userVerified,
    })
  );
}

export async function checkForPreviousLogin(setUserVerified, setUserData, setItemList, Disconnect) {
  let previousLoginDet = localStorage.getItem('pipeline');
  const PROJECT_ID = localStorage.getItem('pipeline_PID');
  if (!previousLoginDet) return false;
  let previousLoginDetParsed = decryptData(localStorage.getItem('pipeline'));
  let differece = timeDifference(previousLoginDetParsed.time);

  if (differece > 24) {
    localStorage.removeItem('pipeline');
    return false;
  }
  try {
    let userData = await getUserData(PROJECT_ID, previousLoginDetParsed.address);

    setUserData(userData);
    setUserVerified(previousLoginDetParsed.userVerified);
    if(userData && userData.status === 'active'){
        let itemsList = await getItemList(PROJECT_ID, userData.lvl);
        setItemList(itemsList);
    }
  } catch (error) {
    Disconnect();
  }
  return true;
}

function timeDifference(datePrev) {
  const currentDate = new Date();
  const savedDate = new Date(datePrev);
  const timeDifference = currentDate - savedDate;
  const hoursDifference = timeDifference / (1000 * 60 * 60);
  return hoursDifference;
}

const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY_CRYPTO).toString();
};

const decryptData = (encryptedData) => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY_CRYPTO);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export function formatNumberWithPrecision(number, precision = 2) {
  let numberConv = number;
  if (typeof number === 'string') {
    numberConv = parseFloat(number);
  }
  const abbreviations = ['', 'K', 'M', 'B', 'T'];
  let index = 0;
  let num = numberConv;

  while (num >= 1000 && index < abbreviations.length - 1) {
    num /= 1000;
    index++;
  }

  const roundedNumber = num.toFixed(precision);

  const formattedNumber = parseFloat(roundedNumber);

  return {
    number: formattedNumber,
    abb: abbreviations[index],
  };
}

export function TruncateString(text, length = 5) {
  if (!text) return '.........';

  if (text.length <= length) {
    return <span>{text}</span>;
  }

  const truncatedText = text.substring(0, length - 1) + '...' + text.slice(-4);

  return <span>{truncatedText}</span>;
}
