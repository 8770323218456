import { BASE_URL } from '../../constant';

export async function auth(projectID, pubKey, nonce, signature) {
  const url = BASE_URL + '/auth';
  const data = {
    d: projectID,
    k: pubKey,
    n: nonce,
    s: signature,
    w: 'unisat',
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const jsonData = await response.json();
      return jsonData;
    } else {
      const errorMessage = await response.text();
      throw new Error(errorMessage);
    }
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function generateNonce(projectID, pubKey) {
  const url = BASE_URL + '/generateN';
  const data = {
    d: projectID,
    k: pubKey,
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const jsonData = await response.json();
      return jsonData;
    } else {
      const errorMessage = await response.text();
      throw new Error(errorMessage);
    }
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function getUserData(projectID, address) {
  const url = `${BASE_URL}/getUserData?d=${projectID}&addr=${address}`;
  
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const jsonData = await response.json();
      return jsonData;
    } else {
      const errorMessage = await response.text();
      throw new Error(errorMessage);
    }
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function getProjectData(projectID, setErrorToast) {
  const url = `${BASE_URL}/getProjectData?d=${projectID}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const jsonData = await response.json();
      if (!jsonData.active) {
        localStorage.removeItem('pipeline');
        setErrorToast('Error: Project not active');
      }
      return jsonData;
    } else {
      const errorMessage = await response.text();
      throw new Error(errorMessage);
    }
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function getItemList(projectID, lvl) {
  const url = `${BASE_URL}/getItemList?d=${projectID}&lvl=${lvl}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const jsonData = await response.json();
      return jsonData;
    } else {
      const errorMessage = await response.text();
      throw new Error(errorMessage);
    }
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function generateItemNonce(projectID, pubKey, item) {
  const url = BASE_URL + '/generateIN';
  const data = {
    d: projectID,
    k: pubKey,
    i: item
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const jsonData = await response.json();
      return jsonData;
    } else {
      const errorMessage = await response.text();
      throw new Error(errorMessage);
    }
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function unlockItem(projectID, pubKey, nonce, signature, item) {
  const url = BASE_URL + '/unlockItem';

  const data = {
    d: projectID,
    k: pubKey,
    n: nonce,
    s: signature,
    i: item,
    w: 'unisat',
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const jsonData = await response.json();
      return jsonData;
    } else {
      const errorMessage = await response.text();
      throw new Error(errorMessage);
    }
  } catch (error) {
    throw new Error(error);
  }
}
