import React from 'react';

import { useAppContext } from '../../../AppContext';

function DetailsPopup() {
  const { selectedDetailsItem, setShowDetailsPopup, projectData } = useAppContext();

  return (
    <div className="modal-content">
      <div className="modal-body p-0">
        <button
          type="button"
          onClick={() => setShowDetailsPopup(false)}
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
        <div className="row g-0">
          <div className="col-lg-6">
          <video autoPlay loop muted style={{ width: '100%', height: 'auto' }}>
          <source src={selectedDetailsItem?.asset} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
            {/* <img src={selectedDetailsItem?.img} alt="" className="img-fluid" /> */}
          </div>
          <div className="col-lg-6">
            <div className="popup-content">
              <div>
                <h4>Item Hash</h4>
                <h3>{selectedDetailsItem?._id}</h3>
              </div>
              <div>
                <h4>Name</h4>
                <h3>{selectedDetailsItem?.rarity === 1 ? (<span>{selectedDetailsItem?.name}</span>) : selectedDetailsItem?.name}</h3>
              </div>
              <div>
                <div style={{ display: 'flex'}}>
                  <div style={{ marginRight: '40px' }}>
                    <h4>Level Required</h4>
                    <h3><span>{selectedDetailsItem?.lvl === -1 ? 'ALL': selectedDetailsItem?.lvl}</span></h3>
                  </div>
                  <div>
                    <h4>Cost</h4>
                    <h3><span>{selectedDetailsItem?.spent}</span> {projectData?.rname ?? 'XP'}</h3>
                  </div>
                </div>
              </div>

              <div>
                <h4>Description</h4>
                <p>{selectedDetailsItem?.desc}</p>
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsPopup;
