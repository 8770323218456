import React from 'react';
import Slider from 'react-slick/lib/slider';
import { SlickSliderSettings } from '../../utils/helper/SlickSlider';
import { useAppContext } from '../../AppContext';

function ClaimRewards() {
  const { setSelectedItem, setShowClaimPopup, itemsData, projectData, userData } = useAppContext();

  const hasItems = itemsData && itemsData.length > 0;

  const handleClaimClick = (item) => {
    if (userData && userData.xp > item.price) {
      setSelectedItem(item);
      setShowClaimPopup(true);
    }
  };

  return (
    <div className="panel">
      <div className="panel-title text-center mb-4">
        <h3>Shop</h3>
      </div>
      {hasItems ? (
        <Slider {...SlickSliderSettings}>
          {itemsData.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / 4);
            if (!resultArray[chunkIndex]) {
              resultArray[chunkIndex] = [];
            }
            resultArray[chunkIndex].push(item);
            return resultArray;
          }, []).map((group, index) => (
            <div key={index} className="cards-block">
              <div className="row g-3">
                {group.map((item) => (
                  <div key={item._id} className="col-6">
                    <div className={`card ${userData && (userData.xp > item.price) ? '' : 'locked'}`} onClick={() => handleClaimClick(item)} style={{ cursor: 'pointer' }}>
                      <div className="img-block">
                        
                        <img src={item.prev} alt={item.name} />
                      </div>
                      <div className="dis">
                        <h4>{item.rarity === 1 ? (<span>{item.name}</span>) : item.name}<p></p><span>{item.price}</span> {projectData?.rname ?? 'XP'} Required</h4>
                      </div>
                      <div className="action">
                        <button data-bs-toggle="modal" data-bs-target="#claimPopup" onClick={() => handleClaimClick(item)}>
                          <span className="icon icon-claim"></span>
                          <span className="txt">CLAIM</span>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </Slider>
      ) : (
        <div className="empty-container">No items available.</div>
      )}
    </div>
  );
}

//   return (
//     <div className="panel">
//       <div className="panel-title text-center mb-4">
//         <h3>Shop</h3>
//       </div>
//       {hasItems ? (
//         <div className="cards-carousel">
//           <Slider {...SlickSliderSettings}>
//             {itemsData.map((item) => (
//               <div key={item._id}>
//                 <div className="cards-block">
//                   <div className="row g-3">
//                     <div className="col-6">
//                       <div className={`card ${item.mintable ? '' : 'locked'}`}>
//                         <div className="img-block">
//                           <img src={item.img} alt={item.name} />
//                         </div>
//                         <div className="dis">
//                           <h4>
//                             {item.name}<p></p> <span>{item.price}</span> {projectData?.rname ?? 'XP' + 'Required'}
//                           </h4>
//                         </div>
//                         <div className="action">
//                           <button data-bs-toggle="modal" data-bs-target="#claimPopup" onClick={() => handleClaimClick(item)}>
//                             <span className="icon icon-claim"></span>
//                             <span className="txt">CLAIM</span>
//                           </button>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </Slider>
//         </div>
//       ) : (
//         <div className="empty-container">No items available.</div>
//       )}
//     </div>
//   );
// }

export default ClaimRewards;
