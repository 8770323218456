import React from 'react';
import Logo from '../../../assets/images/logo.svg';
import { useAppContext } from '../../../AppContext';
import { getPrecision, payFees } from '../../../utils/helper';

function StackinPopup() {
  const { userVerified, setErrorToast, setUserData, setUserVerified } = useAppContext();

  return (
    <div className="modal-content">
      <div className="modal-body p-3">
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
        <div className="popup-content">
          <div className="w-75 mx-auto d-block mt-4">
            <img src={Logo} alt="" className="d-block mx-auto w-50" />
          </div>
          <div className="w-75 mx-auto d-block mt-4 text-center">
            <p>One time fee per project</p>
          </div>
          <div className="w-75 mx-auto d-block mt-4">
            <button
              className="th-btn"
              onClick={() => payFees(userVerified, setErrorToast, setUserData, setUserVerified)}
            >
              Pay{' '}
              {userVerified &&
                userVerified.fees &&
                userVerified.fees.toFixed(getPrecision(userVerified.fees))}{' '}
              BTC to Unlock
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StackinPopup;
