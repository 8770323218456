import { createContext, useContext, useState } from 'react';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [showWallet, setShowWallet] = useState(false);
  const [projectData, setProjectData] = useState(null);
  const [itemsData, setItemList] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedDetailsItem, setSelectedDetailsItem] = useState(null);

  const [isNotiMessage, setIsNotiMessage] = useState(null);
  const [isErrorMessage, setIsErrorMessage] = useState(null);
  const [showClaimPopup, setShowClaimPopup] = useState(false);
  
  const [showXRPOverview, setShowXRPOverview] = useState(false);
  const [userVerified, setUserVerified] = useState({
    fees: 0,
    verified: false,
  });
  const [showConnectPopup, setShowConnectPopup] = useState(true);
  const [showStakingPopup, setShowStakingPopup] = useState(false);
  const [showDetailsPopup, setShowDetailsPopup] = useState(false);
  
  function setErrorToast(message) {
    setIsErrorMessage(message);
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
      setIsErrorMessage(null);
    }, 10000);
  }

  function Disconnect() {
    localStorage.removeItem('pipeline_PID');
    localStorage.removeItem('pipeline');
    setUserData(null);
    setUserVerified({
      fees: 0,
      verified: false,
    });
  }

  function setNotificationToast(message) {
    setIsNotiMessage(message);
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
      setIsNotiMessage(null);
    }, 10000);
  }

  const values = {
    userData,
    showToast,
    showWallet,
    Disconnect,
    projectData,
    setUserData,
    userVerified,
    setShowToast,
    setErrorToast,
    isNotiMessage,
    setShowWallet,
    isErrorMessage,
    showClaimPopup,
    setProjectData,
    showXRPOverview,
    setUserVerified,
    setIsNotiMessage,
    showConnectPopup,
    showStakingPopup,
    setShowClaimPopup,
    setShowXRPOverview,
    setShowStakingPopup,
    setShowConnectPopup,
    setNotificationToast,
    itemsData,
    setItemList,
    selectedItem,
    setSelectedItem,
    showDetailsPopup,
    setShowDetailsPopup,
    selectedDetailsItem,
    setSelectedDetailsItem
  };

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
  return useContext(AppContext);
};
